<template>
  <v-container fluid>
    <v-row>
      <v-col md="12" cols="12">
        <div class="conOfHeader">
          <div class="headerOfPage">Project Attendance</div>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <div class="d-flex justify-space-between mb-5">
        <form class="conFormSubmit conSearchForm mt-5">
          <v-row>
            <v-col md="4" sm="6" cols="12">
              <label class="eachLabel">Project Name</label>
              <v-select
                outlined
                v-model="project_id"
                :items="projectList"
                @change="getTasksList(project_id)"
                item-text="project_name"
                item-value="id"
                placeholder="project Name"
                hide-details="true"
                clearable
                append-icon="mdi-chevron-down"
              ></v-select>
            </v-col>
            <v-col md="4" sm="6" cols="12">
              <label class="eachLabel">Task Name</label>
              <v-select
                outlined
                v-model="task_id"
                :items="tasksList"
                item-text="name"
                item-value="id"
                placeholder="task Name"
                hide-details="true"
                clearable
                append-icon="mdi-chevron-down"
                v-bind="attrs"
                v-on="on"
              ></v-select>
            </v-col>
            <v-col cols="12" md="4">
              <label class="eachLabel">Date</label>

              <v-menu
                ref="menu1"
                v-model="menu1"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    outlined
                    label="date"
                    v-model="date"
                    persistent-hint
                    v-bind="attrs"
                    @blur="date = parseDate(dateFormatted)"
                    clearable
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="date"
                  no-title
                  @input="menu1 = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col md="4" sm="6" cols="12" class="d-flex align-end">
              <div class="confBtns">
                <v-btn
                  class="srearchBnt btnAddPrimaryOutline mr-4"
                  @click="getListData(page)"
                  :disabled="isLoadingSearch"
                  :loading="isLoadingSearch"
                >
                  SEARCH
                </v-btn>
              </div>
            </v-col>
          </v-row>
        </form>
      </div>
    </v-row>
    <v-row>
      <v-col cols="12">
        <section class="conOfProjects">
          <div class="conOfTable">
            <v-data-table
              :headers="headers"
              :items="listData"
              hide-default-footer
            >
              <template v-slot:[`item.team`]="{ item }">
                <div v-for="user in item.team" :key="user.user_name">
                  {{ user.user_name }}
                </div>
              </template>
              <template v-slot:[`item.action`]="{ item }">
                <div v-if="project_id && date && task_id">
                  <a @click="getTaskData()">
                    {{ item.action }}
                    <v-icon class="eye">mdi-eye</v-icon>
                  </a>
                </div>
              </template>
            </v-data-table>
          </div>
        </section>
        <div class="conOfPagination pr-5 pt-5">
          <v-pagination
            :total-visible="7"
            v-model="pagination.current_page"
            :length="pagination.last_page"
            @input="goToPage(pagination.current_page)"
          ></v-pagination>
        </div>
      </v-col>
    </v-row>
    <v-dialog v-model="dialogTaskDetails" max-width="800" persistent>
      <v-card class="conOfPopup">
        <v-card-title>Task details </v-card-title>
        <v-data-table
          :headers="taskHeaders"
          :items="taskData"
          hide-default-footer
        >
        </v-data-table>

        <v-card-actions class="pb-10">
          <v-spacer></v-spacer>
          <v-btn class="btnCancel" tile @click="dialogTaskDetails = false">
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { ApiService } from "@/modules/shared/services/api";
let apiServices = new ApiService();

export default {
  data: () => ({
    date: "",
    menu1: false,
    dialogTaskDetails: false,
    projectList: [],
    tasksList: [],
    taskData: [],
    pagination: {
      current_page: 1,
    },
    headers: [
      { text: "Client", value: "client" },
      { text: "Sales", value: "sales" },
      { text: "Team", value: "team" },
      { text: "From", value: "from" },
      { text: "To", value: "to" },
      { text: "", value: "action", sortable: false },
    ],
    taskHeaders: [
      { text: "Task Name", value: "task" },
      { text: "Sales Name", value: "sales" },
      { text: "Client Name ", value: "team" },
      { text: "Task Describtion", value: "from" },
      { text: "Total Hours", value: "total_hours" },
      { text: "type", value: "type" },
    ],

    project_id: "",
    task_id: "",
  }),
  computed: {},
  methods: {
    getProjectList() {
      this.projectList = [];
      this.isLoadingUsersList = true;
      apiServices.get(`done/projects`).then((res) => {
        if (res) {
          this.projectList = res.data;
          this.isLoadingUsersList = false;
        } else this.isLoadingUsersList = false;
      });
    },
    getTasksList(project_id) {
      this.tasksList = [];
      this.task_id = "";

      this.isLoadingTasksList = true;
      apiServices.get(`tasks?project_id=${project_id}`).then((res) => {
        if (res) {
          this.tasksList = res.data;
          this.task_id = this.tasksList.id.toString();
          console.log("task_id", this.task_id);

          this.isLoadingTasksList = false;
        } else this.isLoadingTasksList = false;
      });
    },
    goToPage(page) {
      this.getListData(page);
      window.scrollTo(0, 0);
    },
    getListData(page) {
      const project_id = this.$route.params.project_id || this.project_id;
      const task_id = this.$route.params.task_id || this.task_id;
      this.listData = [];
      this.Team = {};
      apiServices
        .get(
          `project/attendance?per_page=7&page=${page}&project_id=${project_id}&task_id=${task_id}`
        )
        .then((res) => {
          if (res) {
            this.isLoadingSearch = false;
            this.listData = res.data;
            this.Team = res.data.team;
            this.pagination = res.meta;
            console.log("team", this.Team);
            console.log("ListData", res.data);
            console.log("pagination", this.pagination);

            this.isLoading = false;
          } else this.isLoading = false;
        });
    },
    getTaskData(page) {
      // this.taskData = [];
      // let editFormItem = {
      //   project_id: item.project_id,
      //   date: item.date,
      //   task_id: item.task_id,
      //   user_id: item.user_id,
      // };
      // this.formItem = editFormItem;
      this.dialogTaskDetails = true;
      apiServices
        .get(
          `view_attendance?per_page=7&page=${page}&project_id=${
            this.project_id
          }&date=${this.date}&task_id=${this.task_id}&user_id=${1}`
        )
        .then((res) => {
          if (res) {
            this.isLoadingSearch = false;
            this.taskData = res.data;
            console.log("taskData", res.data);
            console.log("pagination", this.pagination);

            this.isLoading = false;
          } else this.isLoading = false;
        });
    },
    // getListDataSearch() {
    //   this.listData = [];
    //   apiServices
    //     .get(
    //       `project/attendance?project_id=${this.project_id}&task_id=${this.task_id}`
    //     )
    //     .then((res) => {
    //       console.log("ListData", this.listData);
    //       if (res) {
    //         // console.log("ListData", this.listData);
    //         store.commit("setCreatedByUser", this.listData.user);
    //         this.isLoadingSearch = false;
    //         this.listData = res.data;
    //         this.pagination = res.meta;
    //         console.log("pagination", this.pagination);
    //         this.isLoading = false;
    //       } else this.isLoading = false;
    //     });
    // },
  },
  created() {
    this.getListData(this.pagination.current_page);
    this.getProjectList();
    this.getTasksList();
    // this.getTaskData();
  },
  watch: {},
};
</script>
<style lang="scss" scoped>
.salesLable {
  padding: 10px;
  border: 2px solid black;
  font-size: 18px;
  background-color: whitesmoke;
  border-radius: 5px;
}
.eye {
  background-color: #eef3f9;
  border: 1px solid black;
  border-radius: 5px;
  padding: 5px 5px;
  text-decoration: none;
}
.v-application a {
  cursor: pointer;
  text-decoration: none;
}
</style>
